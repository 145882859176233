import React, { FunctionComponent } from 'react';
import Section from "../components/Section";
import Hero from "../components/Hero";
import FeaturedPostImage from "../assets/images/FeaturedPostImage.jpg";
import Heading from "../components/Heading";
import {Link} from "gatsby";

interface OwnProps {}

type Props = OwnProps;

const Blog: FunctionComponent<Props> = (props) => {

    return (
        <>
            <Section className={'py-12'}>
                {/* Featured post */}
                <div className={'lg:p-12 bg-brand-5 grid lg:grid-cols-2 lg:gap-16'}>
                    <div className={''}>
                        <Link to={'/sk/blog/comparison-of-trades-and-p-r-about-in-terms-of-the-tax-burden-in-2020-an-example'}>
                            <img src={FeaturedPostImage} alt="" className={'w-full'}/>
                        </Link>
                    </div>
                    <div className={'p-4'}>
                        <p className={'text-sm text-ink-60 opacity-50'}>
                            27 April 2021
                        </p>
                        <Link to={'/sk/blog/comparison-of-trades-and-p-r-about-in-terms-of-the-tax-burden-in-2020-an-example'}>
                        <Heading type='h4' className={'mt-4 font-semibold lg:text-h3'}>Comparison of trades and p. r. about. in terms of the tax burden in 2020 - an example</Heading>
                        </Link>
                        <p className={'mt-4'}>
                            Answer misery adieus add wooded how nay men before though. Pretended belonging contented mrs suffering.
                        </p>
                        <p className={'mt-4'}>
                            <Link to={'/sk/blog/comparison-of-trades-and-p-r-about-in-terms-of-the-tax-burden-in-2020-an-example'}>
                            Continue Reading
                            </Link>
                        </p>
                    </div>
                </div>
            </Section>
            <Section>
                <div className={'grid lg:grid-cols-3 lg:gap-12'}>
                    <div className={'col-span-2 space-y-16'}>
                        {Array(5).fill().map(() => (
                            <div className={'grid lg:grid-cols-2'}>
                                <div className={'lg:order-2 lg:max-w-xs lg:justify-self-end'}>
                                    <Link to={'/sk/blog/comparison-of-trades-and-p-r-about-in-terms-of-the-tax-burden-in-2020-an-example'}>
                                        <img src={FeaturedPostImage} alt="" className={'w-full'}/>
                                    </Link>
                                </div>
                                <div>
                                    <p className={'text-sm text-ink-60 opacity-50'}>
                                        27 April 2021
                                    </p>
                                    <Heading type='h5' className={'mt-4 font-semibold'}>Comparison of trades and p. r. about. in terms of the tax burden in 2020 - an example</Heading>
                                    <p className={'mt-4'}>
                                        Answer misery adieus add wooded how nay men before though. Pretended belonging contented mrs suffering.
                                    </p>
                                    <p className={'mt-4'}>
                                        <Link to={'/sk/blog/comparison-of-trades-and-p-r-about-in-terms-of-the-tax-burden-in-2020-an-example'}>
                                        Continue Reading
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={''}>
                        <div className={''}>Search</div>
                        <div className={'mt-6'}>
                            <Heading type='h5' className={'mb-4 font-semibold'}>Featured posts</Heading>
                            <div className={'space-y-6'}>
                                {Array(4).fill().map(() => (
                                    <div>
                                        <div>
                                            <p className={'text-sm text-ink-60 opacity-50 mb-1'}>
                                                16 December 2020
                                            </p>
                                            <Link to={'/'} className={'text-ink-60 font-semibold'}>Confirmation of an exception on the way to work or business during a curfew</Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={'mt-16'}>
                            <Heading type='h5' className={'mb-4 font-semibold'}>Categories</Heading>
                            <div className={'space-y-6'}>
                                {Array(5).fill().map(() => (
                                    <div className={'flex justify-between items-center'}>
                                        <Link to={'/'} className={'text-ink-60 font-semibold'}>Živnosť (Freelance)</Link>
                                        <p className={'text-sm text-ink-60 opacity-50 mb-1'}>(10)</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Blog;
