import React, { FunctionComponent } from 'react';

const Container: FunctionComponent = ({ children }) => {

    return (
        <div className={'container'}>
            { children }
        </div>
    );
};

export default Container;
