import React, {FunctionComponent, HTMLProps, ReactNode} from 'react';
import classNames from "classnames";

type Props = {
    type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5',
    as?: string,
} & HTMLProps<any>;

const Heading: FunctionComponent<Props> = ({type = 'h1', as, className= '', ...props}) => {
    const Component = as || type || 'h1';
    const classes = classNames(
        `text-${type} ${className}`,
        ['h1', 'h2', 'h3', 'h4'].includes(type) ? 'font-sans' : 'font-serif',
        ['h1', 'h2'].includes(type) ? 'font-bold' : '',
    );

    return (
        <>
            <Component className={classes} {...props}>
                {props.children}
            </Component>
        </>
    );
};

export default Heading;
