import React, {FunctionComponent, HTMLProps} from 'react';
import Container from "./Container";

interface SectionProps extends HTMLProps<any> {
    fullWidth?: boolean;
}

const Section: FunctionComponent<SectionProps> = ({fullWidth = false, children, className}) => {

    return (
        <section className={`w-full ${className}`}>
            {fullWidth ? (
                children
            ) : (
                <Container>{children}</Container>
            )}
        </section>
    );
};

export default Section;
